








































import {Component, Vue, Watch} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import serverApi from "@/Api/Server/ServerApi";
import ServerResponse from "@/Api/Server/Response/ServerResponse";
import UserStore from "@/Store/Modules/UserStore";
import PaginateObject from "@/Api/PaginateObject";
import ServersResponse from "@/Api/Server/Response/ServersResponse";

@Component
export default class ServerIndex extends Vue {
    menuStore: MenuStore = getModule(MenuStore);
    userStore: UserStore = getModule(UserStore);
    sortBy: string = localStorage.getItem('servers-sort-by') ?? '';
    sortDesc: boolean = localStorage.getItem('servers-sort-desc') === "true";
    loading: boolean = true;

    headers: object = [
        {
            text: '',
            align: 'start',
            value: 'status',
            width: '20px'
        },
        {
            text: this.$t('server.serverName'),
            align: 'start',
            value: 'name',
        },
        {
            text: this.$t('server.ip'),
            value: 'ip_address'
        },
        {
            text: this.$t('server.uptime'),
            value: 'uptime',
            sortable: false
        },
        {
            text: this.$t('createdAt'),
            value: 'created_at'
        }
    ];

    totalItems: number = 0;
    options: any = {};
    servers: ServersResponse = {
        data: [],
        links: {first: "", last: "", next: "", prev: ""},
        meta: {current_page: 0, from: 0, last_page: 0, links: [], per_page: 0, to: 0, total: 0}
    };

    mounted() {
        this.menuStore.addButtons([{
            name: (this.$t('server.create') as string),
            path: '/servers/new'
        }]);
        this.getServers();

        window.Echo.private('user.' + this.userStore.getUser?.id).listen('.server.install.package',() => {
            this.getServers();
        });
    }

    getServers()
    {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const paginate: PaginateObject = {
            page: page,
            per_page: itemsPerPage,
            sort_by: sortBy,
            sort_desc: sortDesc,
        }
        serverApi.list(paginate).then((response) => {
            this.servers = response.data as ServersResponse;
            this.totalItems = this.servers.meta.total;
            this.loading = false;

            this.menuStore.addSubtitle((this.$tc('server.servers', this.totalItems, { count: this.totalItems }) as string));
        });
    }

    @Watch('options')
    handler() {
        this.getServers();
    }

    sortByUpdate(type: string)
    {
        if (type === undefined) {
            localStorage.removeItem('servers-sort-by');
        } else {
            localStorage.setItem('servers-sort-by', type);
        }
    }

    sortDescUpdate(desc: boolean)
    {
        if (desc === undefined) {
            localStorage.removeItem('servers-sort-desc');
        } else {
            localStorage.setItem('servers-sort-desc', String(desc));
        }
    }

    handleClick(server: ServerResponse)
    {
        this.$router.push('/servers/' + server.id);
    }

    beforeDestroy() {
        window.Echo.private('user.' + this.userStore.getUser?.id).stopListening('.server.install.package');
        this.menuStore.clear();
    }
}
