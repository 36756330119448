var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.servers.data,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalItems,"options":_vm.options,"items-per-page":50,"footer-props":{
                    itemsPerPageOptions: [10, 15, 25, 50],
                },"item-key":"ip","loading":_vm.loading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.sortByUpdate],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.sortDescUpdate],"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                var item = ref.item;
return [(item.status === -1)?_c('v-badge',{staticClass:"pulse",attrs:{"color":"orange","inline":"","dot":""}}):_vm._e(),(item.status === 0)?_c('v-badge',{attrs:{"color":"red","inline":"","dot":""}}):_vm._e(),(item.status === 1)?_c('v-badge',{attrs:{"color":"green","inline":"","dot":""}}):_vm._e()]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)),_c('br'),(item.install_state !== null)?_c('small',[_c('i',[_vm._v(_vm._s(_vm.$t('server.provisioning', {percentage: item.install_state})))])]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }